.seat-planner-app {
  font-family: sans-serif;
  background-color: #fff;
  background-image: url(assets/background.png);
  height:100%;
  width:100%;
}

:focus-visible {
  border:none;
  outline:none;
}

.seat-planner-app #canvas-container, .seat-planner-app .sample-canvas {
  height: 100vh;
  width:100%;
  margin: 0 auto;
}


#mode-placement .sample-canvas {
  max-width:100%;
  
}

.styles-module_tooltip__mnnfp {
  z-index:10000;
}
